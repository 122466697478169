var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'w-100': _vm.isCheckoutPage }},[_c('header',{staticClass:"modal-header py15 px65 h2 serif weight-700 center-sm hidden-xs relative"},[_c('img',{staticClass:"modal-close filter-color",attrs:{"src":"/assets/svg/Krzyzyk.svg","width":"24px","height":"24px","alt":"krzyzyk"},on:{"click":_vm.close}}),_vm._v("\n    "+_vm._s(_vm.$t('Login to your account'))+"\n  ")]),_vm._v(" "),(_vm.hasRedirect)?_c('div',{staticClass:"pt10 pb10 px65 redirect-error"},[_c('p',{staticClass:"h5 mb0 mt0"},[_vm._v("\n      "+_vm._s(_vm.$t('You need to be logged in to see this page'))+"\n    ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal-content pb60 px65 cl-secondary",class:[{ 'pt60' : !_vm.isMobile }, { 'full-screen-login' : _vm.isMobile && !_vm.isCheckoutPage }]},[(!_vm.isCheckoutPage)?_c('img',{staticClass:"modal-close visible-xs",attrs:{"src":"/assets/svg/Krzyzyk.svg","width":"24px","height":"24px","alt":"krzyzyk"},on:{"click":_vm.close}}):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile && !_vm.isCheckoutPage),expression:"isMobile && !isCheckoutPage"}],staticClass:"flex center-xs pb20 pt140"},[_c('i',{staticClass:"icon-ag_grzejniki-logo icon-size cl-white"})]),_vm._v(" "),_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('base-input',{staticClass:"mb35",class:{ 'light-version' : _vm.isMobile && !_vm.isCheckoutPage },attrs:{"type":"email","name":"email","placeholder":_vm.$t('E-mail address *'),"light-version":{ true : _vm.isMobile && !_vm.isCheckoutPage },"validations":[
          {
            condition: !_vm.$v.email.required && _vm.$v.email.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.email.email && _vm.$v.email.$error,
            text: _vm.$t('Please provide valid e-mail address.')
          }
        ]},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('base-input',{staticClass:"mb35",class:{ 'light-version' : _vm.isMobile && !_vm.isCheckoutPage },attrs:{"type":"password","name":"password","placeholder":_vm.$t('Password *'),"validations":[{
          condition: !_vm.$v.password.required && _vm.$v.password.$error,
          text: _vm.$t('Field is required.')
        }]},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('base-checkbox',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile || _vm.isCheckoutPage),expression:"!isMobile || isCheckoutPage"}],staticClass:"col-xs-12 col-sm-12 mb20",attrs:{"id":"remember"},model:{value:(_vm.remember),callback:function ($$v) {_vm.remember=$$v},expression:"remember"}},[_vm._v("\n          "+_vm._s(_vm.$t('Remember me'))+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"col-xs-12 col-sm-12 mt0 mb10 flex start-xs middle-xs center-md",class:{'custom-margins' : _vm.isMobile && !_vm.isCheckoutPage}},[_c('a',{class:{'cl-white links' : _vm.isMobile && !_vm.isCheckoutPage},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remindPassword($event)}}},[_c('p',{staticClass:"m0 h5 weight-mobile link-hover"},[_vm._v(_vm._s(_vm.$t('Forgot the password?')))])])]),_vm._v(" "),_c('button-full',{staticClass:"mb20 w-100 uppercase",attrs:{"type":"submit","data-testid":"loginSubmit"}},[_vm._v("\n        "+_vm._s(_vm.$t('Login to your account'))+"\n      ")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCheckoutPage),expression:"!isCheckoutPage"}],staticClass:"center-xs my20 weight-mobile",class:{'cl-white h5' : _vm.isMobile && !_vm.isCheckoutPage}},[_vm._v("\n        "+_vm._s(_vm.$t('Nie masz konta?'))+"\n        "),_c('a',{staticClass:"link-hover",class:{'cl-white links h5' : _vm.isMobile && !_vm.isCheckoutPage},attrs:{"href":"#","data-testid":"registerLink"},on:{"click":function($event){$event.preventDefault();return _vm.switchElem($event)}}},[_vm._v(_vm._s(_vm.$t('Zarejestruj się')))])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }