<template>
  <div :class="{ 'w-100': isCheckoutPage }">
    <header
      class="modal-header py15 px65 h2 serif weight-700 center-sm hidden-xs relative"
    >
      <img src="/assets/svg/Krzyzyk.svg" width="24px" height="24px" alt="krzyzyk" class="modal-close filter-color" @click="close">
      {{ $t('Login to your account') }}
    </header>
    <div v-if="hasRedirect" class="pt10 pb10 px65 redirect-error">
      <p class="h5 mb0 mt0">
        {{ $t('You need to be logged in to see this page') }}
      </p>
    </div>
    <div
      class="modal-content pb60 px65 cl-secondary"
      :class="[{ 'pt60' : !isMobile }, { 'full-screen-login' : isMobile && !isCheckoutPage }]"
    >
      <img src="/assets/svg/Krzyzyk.svg" width="24px" height="24px" alt="krzyzyk" v-if="!isCheckoutPage" class="modal-close visible-xs" @click="close">
      <div class="flex center-xs pb20 pt140" v-show="isMobile && !isCheckoutPage">
        <i class="icon-ag_grzejniki-logo icon-size cl-white" />
      </div>
      <form @submit.prevent="login" novalidate>
        <base-input
          class="mb35"
          :class="{ 'light-version' : isMobile && !isCheckoutPage }"
          type="email"
          name="email"
          v-model="email"
          @blur="$v.email.$touch()"
          :placeholder="$t('E-mail address *')"
          :light-version="{ true : isMobile && !isCheckoutPage }"
          :validations="[
            {
              condition: !$v.email.required && $v.email.$error,
              text: $t('Field is required.')
            },
            {
              condition: !$v.email.email && $v.email.$error,
              text: $t('Please provide valid e-mail address.')
            }
          ]"
        />
        <base-input
          class="mb35"
          :class="{ 'light-version' : isMobile && !isCheckoutPage }"
          type="password"
          name="password"
          v-model="password"
          @blur="$v.password.$touch()"
          :placeholder="$t('Password *')"
          :validations="[{
            condition: !$v.password.required && $v.password.$error,
            text: $t('Field is required.')
          }]"
        />
        <div class="row">
          <base-checkbox
            v-show="!isMobile || isCheckoutPage"
            class="col-xs-12 col-sm-12 mb20"
            id="remember"
            v-model="remember"
          >
            {{ $t('Remember me') }}
          </base-checkbox>
        </div>
        <div
          class="col-xs-12 col-sm-12 mt0 mb10 flex start-xs middle-xs center-md"
          :class="{'custom-margins' : isMobile && !isCheckoutPage}"
        >
          <a
            href="#"
            @click.prevent="remindPassword"
            :class="{'cl-white links' : isMobile && !isCheckoutPage}"
          >
            <p class="m0 h5 weight-mobile link-hover">{{ $t('Forgot the password?') }}</p>
          </a>
        </div>
        <button-full
          class="mb20 w-100 uppercase"
          type="submit"
          data-testid="loginSubmit"
        >
          {{ $t('Login to your account') }}
        </button-full>
        <div
          v-show="!isCheckoutPage"
          class="center-xs my20 weight-mobile"
          :class="{'cl-white h5' : isMobile && !isCheckoutPage}"
        >
          {{ $t('Nie masz konta?') }}
          <a
            href="#"
            @click.prevent="switchElem"
            data-testid="registerLink"
            class="link-hover"
            :class="{'cl-white links h5' : isMobile && !isCheckoutPage}"
          >{{ $t('Zarejestruj się') }}</a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { MobileDetected } from 'theme/components/core/mobileDetected.ts'
import Login from '@vue-storefront/core/compatibility/components/blocks/Auth/Login';
import CurrentPage from 'theme/mixins/currentPage';
import ButtonFull from 'theme/components/theme/ButtonFull.vue';
import BaseCheckbox from '../Form/BaseCheckbox.vue';
import BaseInput from '../Form/BaseInput.vue';
import { required, email } from 'vuelidate/lib/validators';

export default {
  mixins: [Login, CurrentPage, MobileDetected],
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  },
  data () {
    return {
      reCaptchaValid: '',
      hasRedirect: !!localStorage.getItem('redirect')
    };
  },
  methods: {
    submit (response) {
      if (response) {
        this.reCaptchaValid = response;
      }
    },
    responseRecaptcha () {
      this.$refs.recaptcha.getResponse();
    },
    close (e) {
      if (e) localStorage.removeItem('redirect');
      this.$bus.$emit('modal-hide', 'modal-signup');
    },
    login () {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: this.$t('Please fix the validation errors'),
          action1: { label: this.$t('OK') }
        });
        return;
      }
      this.callLogin();
    },
    remindPassword () {
      if (!(typeof navigator !== 'undefined' && navigator.onLine)) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: this.$t(
            'Reset password feature does not work while offline!'
          ),
          action1: { label: this.$t('OK') }
        });
      } else {
        this.callForgotPassword();
      }
    },
    onSuccess () {
      this.$store.dispatch('wishlist/load', true)
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: this.$t('You are logged in!'),
        action1: { label: this.$t('OK') }
      });
    },
    onFailure (result) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t(result.result),
        action1: { label: this.$t('OK') }
      });
    }
  },
  components: {
    ButtonFull,
    BaseCheckbox,
    BaseInput
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$color-error: color(error);
$white: color(white);

.icon-size {
  font-size: 50px;
}
.link-hover {
  @media (min-width: 767px) {
    color: #828282;
    &:hover {
      text-decoration: underline;
    }
  }
}
.filter-color {
  filter: invert(1);
}
.weight-mobile {
  @media (max-width: 767px) {
    font-weight: 100;
  }
}
.evently {
  justify-content: space-evenly;
}
.modal-close {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 10px;
  font-size: 24px;
  padding: 15px 25px;
  @media (max-width: 767px) {
    top: 0;
  }
  @media (max-width: 600px) {
    padding: 15px 15px;
  }
}
.modal-content {
  @media (max-width: 767px) {
    padding-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.redirect-error {
  background-color: $color-error;
  color: $white;
}
.full-screen-login {
  height: 100vh;
  overflow-y: auto;
  max-height: 100vh;
  padding-top: 0px;
  padding-bottom: 0px;
  background: url("/assets/login-bg.png") 0 0 no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  .g-recaptcha-small {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}
.links {
  text-decoration: underline;
}
.custom-margins {
  margin-top: 0px;
  margin-bottom: 20px;
}
.social-icon {
  @media (min-width: 768px) {
    height: 30px;
    border: 1px solid black;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    color: #000;
  }
}
::-webkit-scrollbar {
  -webkit-appearance: none;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background:transparent;
}
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
</style>
